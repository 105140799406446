/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/11/2024
 */

import React from "react";
import { useTranslation } from "react-i18next";
import SubscribePopup from "../../../../WidgetsComponents/SubscribePopup";
import useCalendarPopupHook from "../../../../hooks/useCalendarPopupHook";

/**
 *
 * @param {String} title
 * @param {String} [iCalPath]
 * @returns {Element}
 * @constructor
 */
const SubscribeButton = ({ title, iCalPath }) => {
  const { t } = useTranslation();
  const { onShow, showPopup, onClose } = useCalendarPopupHook();

  const subscribePopup = showPopup ? (
    <SubscribePopup
      title={title}
      onClose={onClose}
      RSSUrl={null}
      iCalPath={iCalPath}
    />
  ) : null;

  return (
    <>
      <a
        href={"#"}
        onClick={onShow}
        target={"_blank"}
        className={"share-event share-event--link"}
        // className={"dc-calendar-share-button"}
      >
        <i className={"material-icons"}>rss_feed</i>
        {t("Subscribe")}
      </a>
      {subscribePopup}
    </>
  );
};

export default SubscribeButton;
