/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/04/2024
 */

import React, { useContext } from "react";
import useShowPopup from "../../../hooks/useShowPopup";
import SharePopup from "../../../WidgetsComponents/SharePopup";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";
import { ShareIcon } from "../../../consts";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {String} title
 * @param {String} url
 * @param {String} text
 * @param {String} [className]
 * @param {String} [iCalPath]
 * @returns {React.JSX.Element|null}
 * @constructor
 */
const ShareCalendarButton = ({
  title,
  url,
  text,
  className = "dc-calendar-share-button",
  iCalPath = null,
}) => {
  const { onShow, showPopup } = useShowPopup({ url, title, text });
  const widgetSettings = useContext(WidgetSettingsContext);
  const { t } = useTranslation();

  const showCalendarShare = widgetSettings["calendarShowShare"] === 1;

  const popup = showPopup ? (
    <SharePopup
      url={url}
      title={title}
      text={text}
      onClose={onShow}
      iCalPath={iCalPath}
    />
  ) : null;

  if (!showCalendarShare) {
    return null;
  }

  return (
    <div>
      <div onClick={onShow} className={className}>
        <i className={"material-icons"}>{ShareIcon}</i> {t("Share")}
      </div>
      {popup}
    </div>
  );
};

export default ShareCalendarButton;
