/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/10/2018.
 */

import React, { useMemo } from "react";
import SidebarTab from "./SidebarTab";

import ApiError from "../ApiError";
import SuccessAlert from "../SuccessAlert";
import PropTypes from "prop-types";
import BuilderOptionsTab from "./DesignTab/BuilderOptionsTab";
import BuilderTabs from "./DesignTab/BuilderTabs";
import BuilderTabContent from "./DesignTab/BuilderTabContent";
import classNames from "classnames";
import { useAppStore } from "../../../../hooks/redux/app";

const DesignTab = (props) => {
  const { widget, hasSetupChanged } = props;
  const { app } = useAppStore();

  const hasFeaturedHighlights = useMemo(() => {
    return (
      widget.model.widget_settings &&
      widget.model.widget_settings.has_feature_highlights
    );
  }, [widget.model, app.widgetSettingsUpdateTimestamp]);

  const footer = !props.disabled ? (
    <div>
      <SuccessAlert>
        {widget.updateSuccess ? "Your changes have been saved." : null}
      </SuccessAlert>
      <ApiError
        error={widget.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
      {!widget.updateSuccess && !widget.updateError ? (
        <div
          className={classNames(
            "alert d-flex m-0 gap-xxs p-2 justify-content-end align-items-center",
            {
              "alert-warning": hasSetupChanged,
            }
          )}
        >
          {hasSetupChanged ? (
            <div className="text-left">
              Save your changes to make them visible to others!
            </div>
          ) : null}
          <button
            disabled={props.disabled}
            type="submit"
            className="btn btn-secondary"
            onClick={props.onRestore}
          >
            Restore
          </button>{" "}
          <button
            disabled={props.disabled}
            type="submit"
            className="btn btn-primary"
            onClick={props.onSubmit}
          >
            Save
          </button>
        </div>
      ) : null}
    </div>
  ) : null;

  const tabs = useMemo(() => {
    const options = [{ label: "Main design", icon: null, id: "main-design" }];

    if (hasFeaturedHighlights) {
      options.push({
        label: "Featured highlights",
        icon: "star",
        id: "featured-design",
      });
    }
    return options;
  }, [hasFeaturedHighlights]);

  const highlights = useMemo(() => {
    if (hasFeaturedHighlights) {
      return (
        <BuilderTabContent id={"featured-design"} show={false}>
          <BuilderOptionsTab widget={widget} settingsName={"highlights"} />
        </BuilderTabContent>
      );
    }
    return null;
  }, [hasFeaturedHighlights, widget, app.widgetSettingsUpdateTimestamp]);

  return (
    <SidebarTab
      id="design_tab"
      show={false}
      footer={footer}
      scrollContents={true}
      wapperClassName={classNames("chat-wrap-inner builder", {
        "with-featured-highlights": hasFeaturedHighlights,
      })}
    >
      <BuilderTabs tabs={tabs}>
        <BuilderTabContent id={"main-design"} show={true}>
          <BuilderOptionsTab widget={widget} settingsName={"design"} />
        </BuilderTabContent>
        {highlights}
      </BuilderTabs>
    </SidebarTab>
  );
};

DesignTab.defaultProps = {
  settingsName: "design",
};

DesignTab.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  widget: PropTypes.object.isRequired,
  widgetSettings: PropTypes.object,
  settingsName: PropTypes.string,
  hasSetupChanged: PropTypes.bool,
};

export default DesignTab;
