/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/04/2024
 */

import React from "react";
import PropTypes from "prop-types";
import SharePopup from "../../../../WidgetsComponents/SharePopup";
import { useTranslation } from "react-i18next";
import { ShareIcon } from "../../../../consts";
import useCalendarPopupHook from "../../../../hooks/useCalendarPopupHook";

const ShareButton = ({ url, title, text }) => {
  const { onShow, showPopup, onClose } = useCalendarPopupHook();
  const { t } = useTranslation();

  const sharePopup = showPopup ? (
    <SharePopup url={url} title={title} text={text} onClose={onClose} />
  ) : null;

  return (
    <>
      <a
        href={"#"}
        onClick={onShow}
        target={"_blank"}
        className={"share-event share-event--link"}
      >
        <i className="material-icons">{ShareIcon}</i> {t("Share")}
      </a>
      {sharePopup}
    </>
  );
};

ShareButton.defaultProps = {};

ShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default ShareButton;
