/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/04/2024
 */

import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import sleep from "../../../helpers/sleep";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import { useUserStore } from "../../../hooks/redux/user";
import { useDomainsStore } from "../../../hooks/redux/domains";
import { useCalendarsStore } from "../../../hooks/redux/calendars";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import { useHistory } from "react-router-dom";

const OrgSwitchOverlay = (props) => {
  const [modalDom] = useState(document.createElement("div"));

  const { user } = useUserStore();
  const { domains } = useDomainsStore();
  const { calendars } = useCalendarsStore();
  const { integrations } = useIntegrationsStore();
  const history = useHistory();

  useEffect(() => {
    document.body.appendChild(modalDom);

    return () => document.body.removeChild(modalDom);
  }, []);

  const [VFXState, setVFXState] = useState({
    showWrapper: false,
    showContainer: false,
  });

  const changingToOrg = useMemo(() => {
    if (!user.switchingToOrgUUID) {
      return null;
    }
    return user.model.organizations.find(
      (i) => i.uuid === user.switchingToOrgUUID
    );
  }, [user.switchingToOrgUUID]);

  /**
   *
   * @param setState
   * @param state
   * @returns {Promise<void>}
   */
  const setStatePromise = (setState, state) => {
    setState(state);
    return Promise.resolve();
  };

  useEffect(() => {
    if (user.switchOrganization) {
      document.body.style.overflow = "hidden";
      setStatePromise(setVFXState, { showWrapper: true, showContainer: false })
        .then(() => sleep(150))
        .then(() => {
          history.push("/dashboard");
          setStatePromise(setVFXState, {
            showWrapper: true,
            showContainer: true,
          });
        });
    }
  }, [user.switchOrganization]);

  /**
   *
   */
  useEffect(() => {
    if (
      user.switchOrganizationSuccess &&
      domains.fetchSuccess &&
      calendars.fetchSuccess &&
      integrations.fetchSuccess
    ) {
      Promise.resolve()
        .then(() => sleep(2000))
        .then(() => {
          setStatePromise(setVFXState, {
            showWrapper: false,
            showContainer: false,
          }).then(() => {
            document.body.style.overflow = "auto";
          });
        });
    }
  }, [
    user.switchOrganizationSuccess,
    domains.fetchSuccess,
    calendars.fetchSuccess,
    integrations.fetchSuccess,
  ]);

  const switchingToLabel = useMemo(() => {
    if (!changingToOrg) {
      return null;
    }
    if (user.switchOrganization) {
      return `Switching to ${changingToOrg.name} organization...`;
    } else if (user.switchOrganizationSuccess) {
      return `Switched to ${changingToOrg.name} organization successfully!`;
    } else if (user.switchOrganizationError) {
      return `Failed to switch to ${changingToOrg.name} organization. Please try again.`;
    }
  }, [
    changingToOrg,
    user.switchOrganization,
    user.switchOrganizationSuccess,
    user.switchOrganizationError,
  ]);

  const loadingIndicator =
    user.switchOrganization ||
    domains.fetch ||
    calendars.fetch ||
    integrations.fetch ? (
      <LoadingIndicator />
    ) : null;

  return ReactDOM.createPortal(
    <div
      className={classnames("org-backdrop-root", {
        show: VFXState.showWrapper,
      })}
      id="org-switch-wrapper"
    >
      <div
        className={classnames("org-backdrop-container", {
          show: VFXState.showContainer,
        })}
        id={"org-switch-container"}
      >
        <div className={"card"}>
          <div className={"card-body"}>
            {switchingToLabel}
            {loadingIndicator}
          </div>
        </div>
      </div>
    </div>,
    modalDom
  );
};

OrgSwitchOverlay.defaultProps = {};

OrgSwitchOverlay.propTypes = {};

export default OrgSwitchOverlay;
