/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/05/2023
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";

const RuleLabel = ({ filter, rule }) => {
  const label = useMemo(() => {
    if (typeof filter?.fieldComponent === "function") {
      return filter.fieldComponent(rule);
    }

    return filter.field.label;
  }, [rule]);

  return label;
};

RuleLabel.defaultProps = {};

RuleLabel.propTypes = {};

export default RuleLabel;
