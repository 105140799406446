import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classname from "classnames";
import CalendarTypeName from "./CalendarTypeName";
import { Link } from "react-router-dom";
import { copyTextToClipboard } from "../../tools";
import classnames from "classnames";
import { IT } from "../../../../consts";
import HamburderDropdown from "../../component/UI/HamburderDropdown";
import PublishDropdown from "../../component/UI/PublishDropdown";
import appConfig from "../../../../appConfig";

const TableRow = (props) => {
  const [clipboardCopied, setClipboardCopied] = useState(false);

  useEffect(() => {
    if (clipboardCopied) {
      setTimeout(() => setClipboardCopied(false), 300);
    }
  }, [clipboardCopied]);

  /**
   *
   */
  const onUserAction = (callback) => (e) => {
    e.preventDefault();

    callback(props.calendar);

    return false;
  };

  /**
   *
   */
  const onChangeStatus = () => {
    props.onChangeStatus(props.calendar);
  };

  /**
   *
   * @param e
   * @returns {boolean}
   */
  const onCopyToClipboard = useCallback(
    (e) => {
      e.preventDefault();
      copyTextToClipboard(window.location.origin + "/c/" + props.calendar.slug);
      setClipboardCopied(true);

      return false;
    },
    [props.calendar]
  );

  const {
    uuid,
    name,
    integration,
    modified,
    available,
    template,
    show_private_events,
    disabled_date,
  } = props.calendar;

  const disabled = props.disabled;

  const disabledAlert = disabled_date ? (
    <span
      className={"material-icons design-tooltip text-danger"}
      data-toggle="tooltip"
      data-placement="left"
      data-trigger={"hover"}
      data-cusom-class={"tooltip-table"}
      title={"This calendar has been disabled due to plan downgrade."}
    >
      error
    </span>
  ) : null;

  const editUrl = integration
    ? "/calendar/" + uuid
    : "/attach-integration/" + uuid;

  const menuOptions = useMemo(
    () => [
      {
        label: "Edit",
        icon: "edit",
        href: editUrl,
      },
      {
        label: "Clone",
        icon: "content_copy",
        onClick: onUserAction(props.onClone),
      },
      {
        label: "Copy shareable link",
        icon: "share",
        onClick: onCopyToClipboard,
      },
      {
        label: "Delete",
        icon: "delete",
        onClick: onUserAction(props.onDelete),
      },
    ],
    [props.onDelete, props.onClone, onCopyToClipboard]
  );

  return (
    <tr
      className={classnames({
        "table-success blinker": clipboardCopied,
        "table-secondary": uuid == props.calendarEditUUID,
      })}
    >
      <td>
        <Link to={editUrl}>{name}</Link>
      </td>
      <td>
        {integration ? (
          integration.type.toUpperCase() +
          (integration.type !== IT.google ? " (Premium)" : "")
        ) : (
          <span className={"badge badge-danger"}>Disconnected</span>
        )}
      </td>
      <td>
        <CalendarTypeName type={template} />
      </td>
      {appConfig.branding === "dc" ? (
        <td>
          <i
            className={classname("material-icons", {
              "text-primary": show_private_events,
            })}
          >
            {show_private_events ? "visibility" : "visibility_off"}
          </i>
        </td>
      ) : null}
      <td>
        <PublishDropdown
          available={available}
          onChangeStatus={onChangeStatus}
          disabled={disabled}
          customIcon={disabledAlert}
        />
      </td>
      <td align="center">
        {modified ? moment(modified * 1000).format("YYYY-MM-DD HH:mm") : "-"}
      </td>
      <td className="text-right">
        <HamburderDropdown disabled={disabled} options={menuOptions} />
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  calendar: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    available: PropTypes.number.isRequired,
    disabled_date: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  calendarEditUUID: PropTypes.string,
  onChangeStatus: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
};

export default TableRow;
