/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/06/2021.
 */

import React, { useCallback } from "react";
import ColorSetup from "./Input/ColorSetup";
import TextSetup from "./Input/TextSetup";
import NumberSetup from "./Input/NumberSetup";
import DropdownSetup from "./Input/DropdownSetup";
import CheckboxSetup from "./Input/CheckboxSetup";
import { calculateDependency, valueIfAvailable } from "../../../../builder";
import RadioSetup from "./Input/RadioSetup";
import FontSetup from "./Input/FontSetup";
import FontSizeSetup from "./Input/FontSizeSetup";
import PropTypes from "prop-types";
import { BuilderOptionPropTypes } from "./Input/BuilderOptionPropTypes";
import { useBuilderStore } from "../../../../hooks/redux/builder";
import RangeSetup from "./Input/RangeSetup";
import TextAreaSetup from "./Input/TextAreaSetup";
import CssBox from "./Input/CssBox";
import CssDimension from "./Input/CssDimension";

export const AvailableOptionComponents = {
  color: ColorSetup,
  text: TextSetup,
  number: NumberSetup,
  dropdown: DropdownSetup,
  checkbox: CheckboxSetup,
  radio: RadioSetup,
  fontFamily: FontSetup,
  fontSize: FontSizeSetup,
  range: RangeSetup,
  textarea: TextAreaSetup,
  cssBox: CssBox,
  cssDimension: CssDimension,
};

const Group = (props) => {
  const { group, widget, settingsName } = props;
  const { updateDesignProp } = useBuilderStore();

  const widgetSettings = widget.model.widget_settings[settingsName];
  const template = widget.model.template;

  /**
   *
   * @param field
   * @returns {(function(*): void)|*}
   */
  const onUpdateDesignProp = useCallback(
    (option) => (value) => {
      let updatedValue = value;

      if (option.control.options.updateValue) {
        updatedValue = option.control.options.updateValue(updatedValue);
      }
      updateDesignProp(updatedValue, settingsName);
      setTimeout(window.updateJquery, 200);
    },
    [updateDesignProp]
  );

  const options = group.options
    .map((option, idx) => {
      const OptionComponent = AvailableOptionComponents[option.control.kind];

      if (!OptionComponent) {
        return null;
      }

      let show = true;

      if (option.show) {
        show = calculateDependency(option.show, widgetSettings);
      }

      if (!show) {
        return null;
      }
      let checkDependency = true;
      if (option.checkDependency) {
        checkDependency = calculateDependency(
          option.checkDependency,
          widgetSettings
        );
      }

      return (
        <OptionComponent
          key={`dtgo-${settingsName}-${option.name}-${idx}`}
          updateDesignProp={onUpdateDesignProp(option)}
          settings={widgetSettings || {}}
          {...option}
          show={show}
          showDependencyWarning={checkDependency}
          template={template}
          settingsName={settingsName}
        />
      );
    })
    // remove options that don't have either a component or do not meet show criteria
    .filter((i) => i);

  if (!options.length) {
    return null;
  }

  return (
    <div className="form-group">
      <div className="chat-line nav-tabs-builder-sticky">
        <span className="chat-date">{group.label}</span>
      </div>
      {options}
    </div>
  );
};

Group.defaultProps = {
  settingsName: "design",
};

Group.propTypes = {
  group: PropTypes.shape({
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape(BuilderOptionPropTypes)),
  }),
  widget: PropTypes.object.isRequired,
  searchQuery: PropTypes.string,
  settingsName: PropTypes.string,
};

export default Group;
