/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/11/2024
 */

import React, { useCallback, useContext } from "react";
import WidgetSettingsContext from "../../contexts/WidgetSettingsContext";
import { useTranslation } from "react-i18next";
import RssFeedLink from "./RssFeedLink";
import AddToCalendar from "../EmbeddableAlerts/AddToCalendar";

/**
 *
 * @param {String} title
 * @param {func} onClose
 * @param {String} [RSSUrl]
 * @param {String} [iCalPath]
 * @returns {Element}
 * @constructor
 */
const SubscribePopup = ({ title, onClose, RSSUrl = null, iCalPath = null }) => {
  const calendarSettingsContext = useContext(WidgetSettingsContext);
  const { t } = useTranslation();

  const _onClose = useCallback((e) => {
    onClose(e);
  }, []);

  const showSubscribeOptions =
    Number.parseInt(calendarSettingsContext["popupSubscribeShow"]) === 1;

  const showRSS =
    Number.parseInt(calendarSettingsContext["popupRSSLink"]) === 1;

  const rssFeed = showRSS ? <RssFeedLink url={RSSUrl} /> : null;

  const subscribeToICal = showSubscribeOptions ? (
    <AddToCalendar iCalPath={iCalPath} title={title} />
  ) : null;

  return (
    <div
      className="dc-subscribe-popup dc-subscribe-popup--hidden dc-subscribe-popup--fade-in"
      id="dc-subscribe-popup"
    >
      <div className={"dc-subscribe-popup--card"}>
        <div
          className={"dc-subscribe-popup--card--close"}
          onClick={_onClose}
        ></div>
        <div>
          <div className={"dc-subscribe-popup--card-section--header"}>
            {t("Subscribe to")} {title}
          </div>
          <div className={"dc-subscribe-popup--card-section--subheader"}>
            {t("Open with your calendar app")}
          </div>
          {subscribeToICal}
        </div>

        {rssFeed}
      </div>
    </div>
  );
};

export default SubscribePopup;
