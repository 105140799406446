/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/08/2019.
 */

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import classnames from "classnames";
import DetailsRow from "./DetailsRow";
import EventCTA from "./EventCTA";
import PropTypes from "prop-types";
import EventRSVP from "./EventRSVP";
import localstorage from "local-storage";
import Forms from "./Forms";
import EventImage from "./EventImage";
import PoweredBy from "../PoweredBy";
import { useCalendarStore } from "../../../../hooks/redux/calendar";
import EventBadges from "../EventBadges";
import EventSchedule from "./EventSchedule";
import RecurrenceSeries from "./RecurrenceSeries";
import EventDetailsTime from "./EventDetailsTime";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import ShareButton from "./ShareButton";
import EventPlace from "./EventPlace";
import EventDescription from "./EventDescription";
import { useTranslation } from "react-i18next";
import FeatureFlag from "../../../admin/component/FeatureFlag";
import SubscribeButton from "./SubscribeButton";
import EventDetailsLocation from "./EventDetailsLocation";

const EventDetails = React.memo((props) => {
  const calendarSettingsContext = useContext(WidgetSettingsContext);

  const { t } = useTranslation();
  const [innerContainerHeight, setInnerContainerHeight] = useState(0);
  const [RSVPFormOpen, setRSVPFormOpen] = useState(false);
  const [numberOfNextEvents, setNumberOfNextEvents] = useState(3);

  const showRecurrenceSeries = useMemo(() => {
    return (
      calendarSettingsContext["eventDetailsShowRecurringSeries"] &&
      Number.parseInt(
        calendarSettingsContext["eventDetailsShowRecurringSeries"]
      ) === 1
    );
  }, [calendarSettingsContext]);

  const { calendar } = useCalendarStore();

  const calculateInnerContainerHeight = () => {
    /**
     * calculating the height of inner elements to be able to set max-height that matches
     * the height. Other way, when setting max-height to abstract (big) value makes accordin
     * effect broken.
     * @type {HTMLElement}
     */
    const detailsContainer = document.getElementById(
      "event-details-" + props.event.slug
    );
    if (!detailsContainer) {
      return null;
    }
    let innerContainerHeight = 0;

    for (const i in detailsContainer.children) {
      if (!detailsContainer.children[i].scrollHeight) {
        continue;
      }
      innerContainerHeight += detailsContainer.children[i].scrollHeight;
    }

    return innerContainerHeight;
  };

  useEffect(() => {
    setTimeout(
      () => setInnerContainerHeight(calculateInnerContainerHeight()),
      10
    );
  }, [RSVPFormOpen, numberOfNextEvents, showRecurrenceSeries]);

  /**
   *
   * @param e
   */
  const updateInnerContainerHeightWithImage = (e) => {
    setInnerContainerHeight(innerContainerHeight + e.target.scrollHeight);
  };

  const setRSVPOpen = (open) => {
    setRSVPFormOpen(open);
  };

  //    this.state = { innerContainerHeight: 0 };
  const event = props.event;

  const calendarSlug = props.calendarSlug;

  const formsData =
    event.forms && !Array.isArray(event.forms)
      ? [event.forms]
      : event.forms && Array.isArray(event.forms)
      ? event.forms
      : null;

  const hasForms = formsData && formsData.length ? true : false;

  const startDate = useMemo(
    () => new Date(event.start_time * 1000) /*.utc()*/,
    [event.start_time]
  );

  const endDate = useMemo(
    () => new Date(event.end_time * 1000) /*.utc()*/,
    [event.end_time]
  );

  const registrationType = event.rsvp && event.rsvp.type_id * 1;

  // states whether to show the RSVP in general
  const settingsShowRSVP =
    Number.parseInt(calendarSettingsContext["rsvpShowForOpenForAll"]) === 1;

  // states whether to show the RSVP even for "Open to All" event types
  const settingsShowRSVPForOpenForAll =
    Number.parseInt(calendarSettingsContext["rsvpShowForOpenToAll"]) === 1;

  const localStorageEventKey = "rsvp-event-" + props.event.slug;
  const sessionSelectedRSVPOption = localstorage(localStorageEventKey) || null;

  const RSVP =
    settingsShowRSVP &&
    (registrationType == 2 ||
      (registrationType == 1 && settingsShowRSVPForOpenForAll)) &&
    !hasForms ? (
      <EventRSVP
        key={"ersvp-" + event.slug + "-" + sessionSelectedRSVPOption}
        ccbAddress={props.ccbAddress}
        event={event}
        onRSVPEvent={props.onRSVPEvent}
        loading={props.loading}
        error={props.error}
        selectedRSVPOption={sessionSelectedRSVPOption}
        onShowForm={setRSVPOpen}
      />
    ) : null;

  const showForms =
    Number.parseInt(calendarSettingsContext["formShowForms"]) === 1;

  const showFormDescription =
    Number.parseInt(calendarSettingsContext["formShowDescription"]) === 1;

  const showLocation =
    Number.parseInt(calendarSettingsContext["eventDetailsShowLocation"]) == 1;

  const showImage =
    // Number.parseInt(calendarSettingsContext["eventDetailsShowImage"]) == 1;
    Number.parseInt(calendarSettingsContext["eventDetailsShowImage"]) == 1;
  const imageAspectRatio =
    calendarSettingsContext["eventDetailsImageAspectRatio"];
  const showOrganizer =
    Number.parseInt(calendarSettingsContext["eventDetailsShowOrganizer"]) === 1;
  const showOrganizerEmail =
    Number.parseInt(
      calendarSettingsContext["eventDetailsShowOrganizerEmail"]
    ) === 1;

  const showShareOptions =
    Number.parseInt(calendarSettingsContext["eventDetailsShowShareOptions"]) ===
    1;

  const showRecurrenceDescription =
    Number.parseInt(
      calendarSettingsContext["eventDetailsShowRepeatingEventsTimes"]
    ) === 1 && event.recurrence_description;

  const showRecurringEventSeries =
    Number.parseInt(
      calendarSettingsContext["eventDetailsShowRecurringSeries"]
    ) === 1 && event.recurrence_description;

  const showAddToCalendar =
    Number.parseInt(
      calendarSettingsContext["eventDetailsShowAddToCalendar"]
    ) === 1;

  const showEventTime =
    Number.parseInt(calendarSettingsContext["eventDetailsShowTime"]) === 1;

  const showEventSchedule =
    Number.parseInt(calendarSettingsContext["eventDetailsShowSchedule"]) === 1;

  const showEventDescription =
    Number.parseInt(calendarSettingsContext["generalShowDescription"]) === 1;

  const showShortSummary =
    Number.parseInt(calendarSettingsContext["generalShowShortSummary"]) === 1;

  const recurringShowBadge =
    Number.parseInt(calendarSettingsContext["recurringShowBadgeInDetails"]) ===
    1;

  const featuredShowBadge =
    Number.parseInt(calendarSettingsContext["featuredShowBadgeInDetails"]) ===
    1;

  const badgesLocation = calendarSettingsContext["recurringBadgeLocation"];

  const showPrimaryCta =
    Number.parseInt(calendarSettingsContext["cPopupShowCta1st"]) === 1;

  const showEventTimeOption =
    calendarSettingsContext["eventDetailsShowTimeOptions"];

  const forms =
    showForms && hasForms ? (
      <Forms forms={formsData} showDescription={showFormDescription} />
    ) : null;

  const eventCTA = showPrimaryCta ? (
    <EventCTA
      event={event}
      className={"dce--event-card-details-item dce--event-card--cta"}
      buttonClassName={"dce--event-card--cta-btn"}
    />
  ) : null;

  const eventUrl = `${process.env.BASE_URL}/e/${calendarSlug}/${event.slug}`;

  const organizerName =
    showOrganizerEmail && event.organizer.email != null ? (
      <a
        href={"mailto:" + event.organizer.email}
        className={"event-details--details-anchor anchor "}
      >
        <i className={"material-icons"}>email</i>
        {event.organizer.name}
      </a>
    ) : (
      event.organizer.name
    );

  const shareBlock =
    showShareOptions || showAddToCalendar ? (
      <DetailsRow title={null} icon={null}>
        <div className="event-row--details-row__share-subscribe">
          {showShareOptions ? (
            <ShareButton
              url={eventUrl}
              title={event.name}
              text={event.description}
            />
          ) : null}
          {showAddToCalendar ? (
            <SubscribeButton
              title={event.name}
              iCalPath={`${calendar.model.integration.uuid}/${calendar.model.uuid}/${event.ics_id}.ics`}
            />
          ) : null}
        </div>
      </DetailsRow>
    ) : null;

  const onShowMoreRecurringEvents = useCallback(() => {
    setNumberOfNextEvents(numberOfNextEvents + 3);
  }, [numberOfNextEvents]);

  const recurrenceSeries =
    showRecurrenceDescription &&
    event.recurrence_description /*event.recurrence_series */ ? (
      <RecurrenceSeries
        series={event.recurrence_series}
        eventId={event.slug}
        eventName={event.name}
        description={event.recurrence_description}
        numberOfNextEvents={numberOfNextEvents}
        onShowMore={onShowMoreRecurringEvents}
        showSeries={showRecurringEventSeries ? true : false}
      />
    ) : null;

  const eventImageBlock =
    showImage && event.cover_image ? (
      <EventImage
        src={event.cover_image}
        aspectRatio={imageAspectRatio}
        onLoad={updateInnerContainerHeightWithImage}
      />
    ) : null;

  const organizerBlock =
    showOrganizer && organizerName ? (
      <DetailsRow title={t("Organizer")} icon="headset">
        <div className="">
          <h4>{organizerName}</h4>
        </div>
      </DetailsRow>
    ) : null;

  const embeddedSingleEvent = window.location.pathname.match(
    `/e/.*/${props.expandedRowSlug}`
  )
    ? true
    : false;

  const watermark =
    calendar.model &&
    Number.parseInt(calendar.model.is_watermarked) === 1 &&
    !embeddedSingleEvent ? (
      <div className={"event-row--details"}>
        <div className="event-row--details-row">
          <div className="col-content ">
            <PoweredBy />
          </div>
        </div>
      </div>
    ) : null;

  const eventDateTime = showEventTime ? (
    <div
      className={classnames("col-content", {
        "col-content--right": showLocation,
      })}
    >
      <div className="col-icon">
        <i className="material-icons">schedule</i>
      </div>

      <div>
        <h3 className="">{t("Time of the event")}</h3>
        <EventDetailsTime
          startDate={startDate}
          endDate={endDate}
          isAllDayEvent={event.allDay}
          showEventTimeOption={showEventTimeOption}
        />
      </div>
    </div>
  ) : null;

  const eventSchedule = showEventSchedule ? (
    <EventSchedule eventId={event.slug} schedule={event.eventTimes} />
  ) : null;

  const eventBadges =
    (recurringShowBadge && event.recurrence_description) ||
    (featuredShowBadge && event.is_featured) ? (
      <EventBadges
        location={badgesLocation}
        showRecurringBadge={recurringShowBadge}
        showFeaturedBadge={featuredShowBadge && event.is_featured}
      />
    ) : null;

  const eventShortSummary =
    event.summary && event.summary.length && showShortSummary ? (
      <DetailsRow title={t("Event summary")} icon="reorder">
        <div className="">
          <p
            className={"p"}
            dangerouslySetInnerHTML={{ __html: event.summary }}
          />
        </div>
      </DetailsRow>
    ) : null;

  return (
    <div
      id={"event-details-" + event.slug}
      style={{
        maxHeight:
          props.showDetails || props.expandedRowSlug === event.slug
            ? innerContainerHeight + "px"
            : 0,
      }}
      className={classnames("event-details--wrapper")}
    >
      {eventImageBlock}
      {eventBadges}
      {eventShortSummary}

      {showEventDescription && event.description && event.description.length ? (
        <EventDescription event={event} />
      ) : null}

      {eventCTA}

      <div className={"event-row--details"}>
        <div className="event-row--details-row-doubled">
          <div className={"event-row--details-row-doubled--inner-wrapper"}>
            {eventDateTime}

            <EventDetailsLocation
              location={event.location}
              displayLocation={event.display_location}
            />
          </div>
        </div>
      </div>
      <EventPlace place={event.place} />
      {eventSchedule}

      {forms}
      {RSVP}

      {organizerBlock}

      {recurrenceSeries}

      {shareBlock}

      {watermark}
    </div>
  );
});

EventDetails.defaultProps = {
  showDetails: false,
};

EventDetails.propTypes = {
  showDetails: PropTypes.bool,
  calendarSlug: PropTypes.string,
  embedded: PropTypes.bool,
  onRSVPEvent: PropTypes.func,
  expandedRowSlug: PropTypes.string,
};
EventDetails.whyDidYouRender = true;

export default EventDetails;
