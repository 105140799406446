/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/12/2024
 */

import React from "react";
import AxiosConfig from "../../../../../AxiosConfig";
import { getUploadResourceUrl } from "../../../../../helpers/url";

/**
 *
 * @param {Object} organization
 * @param {string} organization.name
 * @param {Object} organization.settings
 * @returns {Element}
 * @constructor
 */
const OrgLogo = ({ organization }) => {
  if (!organization.settings) {
    return (
      <div className={"nav-user-menu--dropdown-container__list--item--logo "}>
        <div
          className={
            "nav-user-menu--dropdown-container__list--item--logo--name"
          }
        >
          {organization.name.at(0)}
        </div>
      </div>
    );
  }

  return (
    <div className={"nav-user-menu--dropdown-container__list--item--logo "}>
      <img
        className={""}
        src={getUploadResourceUrl(organization.settings.logo)}
      />
    </div>
  );
};

export default OrgLogo;
