/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/11/2023
 */

import React, { useState } from "react";
import BillingDetailsForm from "../../component/BillingDetailsForm";
import BasicDetailsForm from "./BasicDetailsForm";

/**
 *
 * @param {Object} organization
 * @param {Boolean} disabled
 * @param {Function} onSubmit
 * @param {Object} organizationSettings
 * @param {String = "Save Changes"}submitButtonLabel
 * @returns {Element}
 * @constructor
 */
const Form = ({
  organization,
  disabled,
  onSubmit,
  organizationSettings,
  submitButtonLabel = "Save Changes",
}) => {
  const [form, setFormValue] = useState({
    name: organization.name,
    billing_name: organization.billing_name,
    billing_contact: organization.billing_contact,
    billing_address: organization.billing_address,
    treasurer_email: organization.treasurer_email,
    billing_country: organization.billing_country,
    billing_state: organization.billing_state,
    billing_city: organization.billing_city,
    billing_zip: organization.billing_zip,
  });

  /**
   *
   * @param field
   * @returns {(function(*): void)|*}
   */
  const onUpdateField = (data) => {
    setFormValue(data);
  };

  const onInputChange = (field) => (e) => {
    e.preventDefault();

    const data = { ...form };
    data[field] = e.target.value;

    setFormValue(data);
  };

  const _onSubmit = (e) => {
    e.preventDefault();
    onSubmit(form);
    return false;
  };

  return (
    <form onSubmit={_onSubmit}>
      <div className="card-box">
        <div className={"m-b-20"}>
          <BasicDetailsForm
            organization={form}
            onSetValue={onUpdateField}
            organizationSettings={organizationSettings}
          />
        </div>
        <div className="row">
          <div className="col-md-12">
            <BillingDetailsForm
              organization={form}
              onSetValue={onUpdateField}
            />
          </div>
        </div>
        <div className="text-center m-t-20 ">
          <button
            className="btn btn-primary btn-lg"
            type="submit"
            disabled={disabled}
          >
            {submitButtonLabel}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
