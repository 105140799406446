/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/08/2018.
 */

import React from "react";

import { Link } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";

const SideMenuItem = (props) => {
  const beta = props.beta ? (
    <div
      className={"badge badge-primary design-tooltip"}
      data-toggle="tooltip"
      data-placement="top"
      title={"Experimental"}
    >
      BETA
    </div>
  ) : null;

  const alpha = props.alpha ? (
    <div
      className={"badge badge-secondary design-tooltip"}
      data-toggle="tooltip"
      data-placement="top"
      title={"In development"}
    >
      Alpha
    </div>
  ) : null;

  const icon = props.iconImage ? (
    props.iconImage //<img alt={props.label} src={`/img/nav_icons/${props.iconSvg}`} />
  ) : (
    <i className={"material-icons"} aria-hidden="true">
      {props.icon}
    </i>
  );

  const LinkComponent = props.url.startsWith("http") ? "a" : Link;

  return (
    <li
      key={`sidebar-nav-${props.id}`}
      className={classnames("list-unstyled", props.className, {
        active: props.id == props.currentSidebarId || props.active,
        disabled: props.disabled,
      })}
    >
      <LinkComponent
        href={props.url}
        to={props.url}
        className={"d-inline-flex align-items-center w-100"}
        target={props.target || undefined}
      >
        <span className={"d-inline-flex align-items-center w-100"}>
          {icon} {props.label}
        </span>
        {beta}
        {alpha}
      </LinkComponent>
    </li>
  );
};

SideMenuItem.propTypes = {
  id: PropTypes.string,
  currentSidebarId: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  label: PropTypes.string,
  target: PropTypes.string,
  disabled: PropTypes.bool,
  beta: PropTypes.bool,
  alpha: PropTypes.bool,
  url: PropTypes.string,
};

SideMenuItem.defaultProps = {
  disabled: false,
};

export default SideMenuItem;
