import { getMailchimpSnippet, getPreview } from "./listTemplate";
import { getRssFeedUrl } from "./helpers";
import { ucFirst } from "../../../../../../../../helpers/string";

/**
 * @param uuid
 * @param settings
 * @param headerTemplate
 * @param itemTemplate
 * @param widgetModel
 * @returns {`
 ${string}
 *|FEEDBLOCK:${string}|*
 *|FEEDITEMS:[${string|string}$constrain_rss_img=Y]|*
 ${string}
 *|END:FEEDITEMS|*
 *|END:FEEDBLOCK|*
 `}
 */
const generateMCTemplate = (
  uuid,
  settings,
  headerTemplate,
  itemTemplate,
  widgetModel
) => {
  const url = getRssFeedUrl(uuid, settings, widgetModel);
  const itemBlock = itemTemplate(settings);
  const headerBlock = headerTemplate(settings);
  const itemsLimit =
    settings?.eventsLimitOption === "upcoming-limit"
      ? `$count=${settings.eventsLimit}, `
      : "";

  return `
    ${headerBlock}
    *|FEEDBLOCK:${url}|*
    *|FEEDITEMS:[${itemsLimit}$constrain_rss_img=Y]|*
          ${itemBlock}
      *|END:FEEDITEMS|*
    *|END:FEEDBLOCK|*
  `;
};

export const getHTMLPreview = (
  headerTemplate,
  itemTemplate,
  settings,
  events
) => {
  const showAllEvents =
    settings.eventsLimit === "all" || settings.eventsLimitOption === "all";
  const items = events
    .filter((e, idx) => showAllEvents || idx < settings.eventsLimit)
    .map((event) => {
      let itemHTML = itemTemplate({
        ...settings,
        showLocation: settings.showLocation && event.location,
      });

      let startDate = new Date(event.startDate).toLocaleString("en-US", {
        weekday: "short", // Short weekday (e.g., "Sat")
        day: "2-digit", // Two-digit day (e.g., "16")
        month: "short", // Short month (e.g., "Nov")
        year: "numeric", // Full year (e.g., "2024")
        hour: "2-digit", // Two-digit hour
        minute: "2-digit", // Two-digit minutes
        hour12: true, // Use 24-hour format
      });

      // remove second comma in the startDate
      const commaIndex = startDate.indexOf(",", startDate.indexOf(",") + 1);
      startDate =
        startDate.slice(0, commaIndex) + startDate.slice(commaIndex + 1);

      itemHTML = itemHTML
        .replaceAll("*|FEEDITEM:URL|*", event.link)
        .replaceAll("*|FEEDITEM:TITLE|*", event.title)
        .replaceAll("*|FEEDITEM:CONTENT|*", event.description)
        .replaceAll(/\*\|FEEDITEM:DATE:[^|]*\|\*/g, ucFirst(startDate));

      if (event.location) {
        itemHTML = itemHTML.replaceAll(
          "*|FEEDITEM:CATEGORIES|*",
          event.location
        );
      }

      if (event.imgUrl) {
        itemHTML = itemHTML.replaceAll(
          "*|FEEDITEM:IMAGE|*",
          `<img src="${event.imgUrl}" class="mc-rss-item-img" width="100%" height="auto" />`
        );
      } else {
        itemHTML = itemHTML.replaceAll("*|FEEDITEM:IMAGE|*", "");
      }

      return itemHTML;
    })
    .join("");

  return `
  ${headerTemplate(settings)}
  ${items}
  `;
};

const useMailchimpTemplate = (
  uuid,
  headerTemplate,
  itemTemplate,
  settings,
  events,
  widgetModel
) => {
  /**
   * 
   * @param settings
   * @returns {`
 ${string}
|FEEDBLOCK:${string}|*
|FEEDITEMS:[${string}$constrain_rss_img=Y]|*
 ${string}
|END:FEEDITEMS|*
|END:FEEDBLOCK|*
 `}
   */
  const getMailchimpSnippet = () => {
    return generateMCTemplate(
      uuid,
      settings,
      headerTemplate,
      itemTemplate,
      widgetModel
    );
  };

  const getPreview = () => {
    return getHTMLPreview(headerTemplate, itemTemplate, settings, events);
  };

  return {
    getPreview,
    getMailchimpSnippet,
  };
};

export default useMailchimpTemplate;
