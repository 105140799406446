/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/10/2024
 */

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const RssFeedLink = ({ url }) => {
  const { t } = useTranslation();
  if (!url) {
    return null;
  }

  return (
    <div className={"dc-subscribe-popup--card-section--subheader"}>
      {t("Follow live")}
      <a
        href={url}
        className={"dc-subscribe-popup--rss-feed-link"}
        target={"_revRSS"}
      >
        <i className={"material-icons"}>rss_feed</i>
        {t("RSS Feed")}
      </a>
    </div>
  );
};

RssFeedLink.propTypes = {
  url: PropTypes.string,
};

export default RssFeedLink;
