/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/07/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { formatAddressLines } from "../../tools";

const Header = (props) => {
  const { group } = props;

  const meetingDescription = group.meeting
    ? [group.meeting.meeting_day, group.meeting.meeting_time]
        .filter((i) => i)
        .join(", ")
    : null;

  const meeting = meetingDescription ? (
    <span className={"dce-groups-modal--header-datetime_time"}>
      <i className="material-icons">schedule</i>
      {" " + meetingDescription}
    </span>
  ) : null;

  const addressLines = group.address ? formatAddressLines(group.address) : null;
  const locationDescription = addressLines ? addressLines.join(", ") : null;

  const location = locationDescription ? (
    <span className={"dce-groups-modal--header-datetime_location"}>
      <i className="material-icons">location_on</i>
      {" " + locationDescription}
    </span>
  ) : null;

  return (
    <div className={"dce-groups-modal--header"}>
      <h1>{group.name}</h1>
      <div className={"dce-groups-modal--header-datetime"}>
        {meeting}
        {location}
      </div>
    </div>
  );
};

Header.defaultProps = {};

Header.propTypes = {};

export default Header;
