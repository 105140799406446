/**
 * Created by piotr.pozniak@thebeaverhead.com on 22/11/2024
 */

import { useCallback, useState } from "react";

/**
 *
 * @returns {{onClose: (function(*): boolean), onShow: (function(*): boolean), showPopup: boolean}}
 */
const useCalendarPopupHook = () => {
  const [showPopup, setShowPopup] = useState(false);

  /**
   *
   * @type {function(*): boolean}
   */
  const onShow = useCallback(
    (e) => {
      e.preventDefault();
      setShowPopup(true);
      return false;
    },
    [showPopup]
  );

  /**
   *
   * @type {function(*): boolean}
   */
  const onClose = useCallback(
    (e) => {
      e.preventDefault();
      setShowPopup(false);
      return false;
    },
    [showPopup]
  );

  return { showPopup, onClose, onShow };
};

export default useCalendarPopupHook;
