/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/10/2024
 */

import React, { useMemo } from "react";
import WebsiteMissingWarning from "../../WebsiteMissingWarning";
import WebsitesVerifiedInfo from "../../WebsiteVerifiedInfo";
import InstallAccordion from "../../InstallAccordion";
import ClipboardCopyInput from "../../../../component/ClipboardCopyInput";
import FeatureFlag from "../../../../component/FeatureFlag";
import {
  getInstallDivTag,
  getInstallIframeTag,
  installAlertsScripTag,
  installScripTag,
} from "../../../../templates/WidgetPageTemplate/InstallTab/helpers";
import appConfig from "../../../../../../appConfig";
import OneTimerAlert from "./OneTimerAlert";

const ChurchCoInstructions = ({
  uuid,
  slug,
  widgetType,
  widgetTypePrintable,
  onGoToAddWebsite,
}) => {
  return (
    <div>
      {/*<FeatureFlag name={"churchco-embeddable"}>
        <div className={"w-100 d-flex align-items-center flex-column"}>
          <p className={"text-center"}>
            Watch our tutorial video below or{" "}
            <a
              target={"_blankWordpressInstructions"}
              href={
                "https://help.display.church/en/articles/6690434-the-church-co-embedding-your-view"
              }
            >
              Read our Article
            </a>
            .
          </p>
          <div className={"install-modal--video-tutorial"}>
            <div>
              <iframe
                src="https://player.vimeo.com/video/765663052?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                title="Embedding into Wordpress"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </FeatureFlag>*/}

      <div className={"my-4"}>
        <WebsiteMissingWarning onAddWebsite={onGoToAddWebsite} />
        <WebsitesVerifiedInfo />
      </div>
      <InstallAccordion
        title={`Recommended installation`}
        expandable={false}
        defaultExpanded={true}
      >
        <OneTimerAlert />
        <ol>
          <li>
            Navigate to your The Church Co <strong>dashboard</strong> and click
            on <strong>Pages</strong>.
          </li>
          <li>
            Select the page on which you wish to embed the {widgetTypePrintable}
            .
          </li>
          <li>
            Choose <strong>Visual Editor</strong>
          </li>
          <li>
            Click on <strong>New Page Builder Block</strong>.
          </li>
          <li>
            Select an
            <strong>HTML Embed</strong> block. The block will open. Code
            Injection.
          </li>
          <li>
            Click on the <strong>Code</strong> tab.
          </li>
          <li>
            Copy and Paste the following snippet in the{" "}
            <strong>HTML code</strong> field.
            <ClipboardCopyInput
              id={"embeddable_script"}
              value={installAlertsScripTag}
              placeholder={`${widgetTypePrintable} embeddable script`}
            />
          </li>
          <li>
            Click <strong>Save</strong> to confirm your changes.
          </li>
        </ol>
      </InstallAccordion>
    </div>
  );
};

export default ChurchCoInstructions;
