/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/04/2023
 */

import React, { useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import RuleEditDropdown from "./RuleEditDropdown";
import { AvailableRulesOptionsContext, FiltersContext } from "./contexts";
import CrownIcon from "../CrownIcon";
import RuleLabel from "./RuleLabel";

const Rule = ({ groupId, id, rule, onRemoveRule: _onRemoveRule }) => {
  const { newRule, clearNewRule } = useContext(FiltersContext);

  const isNewRule = useMemo(
    () => newRule && newRule.id === id && newRule.groupId === groupId,
    [newRule]
  );

  const [isEditMode, setIsEditMode] = React.useState(isNewRule);

  const availableRulesOptions = useContext(AvailableRulesOptionsContext);

  const optionSettings = useMemo(
    () =>
      availableRulesOptions.find(
        (option) => option.field.value === rule.field.value
      ),
    []
  );

  /**
   *
   * @type {(function(): void)|*}
   */
  const setEditMode = useCallback(() => {
    setIsEditMode(true);
  }, [isEditMode]);

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onRemoveRule = useCallback(
    (e) => {
      // stop propagating otherwise it would try to setEditMode
      e && e.stopPropagation();
      _onRemoveRule(id);
    },
    [_onRemoveRule]
  );

  /**
   *
   * @type {(function(): void)|*}
   */
  const onCloseEditDropdown = useCallback(
    (e) => {
      // stop propagating otherwise it would try to setEditMode
      e && e.stopPropagation();
      setIsEditMode(false);

      if (isNewRule) {
        clearNewRule();
      }
    },
    [isEditMode]
  );

  const editDropdown = isEditMode ? (
    <RuleEditDropdown
      groupId={groupId}
      id={id}
      onClose={onCloseEditDropdown}
      rule={rule}
    />
  ) : null;

  const hasError = !isEditMode && (!rule.value || !rule.value.length);

  const displayValue = !hasError
    ? `${rule.operator.label} ${rule.label}`
    : "is missing value";

  const isAllowed = useMemo(() => {
    if (typeof optionSettings?.isAllowed === "function") {
      return optionSettings.isAllowed(rule);
    }
    return optionSettings?.isAllowed;
  }, [rule]);

  return (
    <div
      onClick={setEditMode}
      className={classNames("filter-group__rule", {
        "filter-group__rule-error": hasError,
        "filter-group__rule-warning": !isAllowed,
        "filter-group__rule-active": isEditMode,
      })}
    >
      <span>
        <strong>
          <RuleLabel rule={rule} filter={optionSettings} />
        </strong>{" "}
        {displayValue}
      </span>
      <div onClick={onRemoveRule} className={"filter-group__rule--remove"}>
        &times;
      </div>
      {editDropdown}
    </div>
  );
};

Rule.defaultProps = {};

Rule.propTypes = {
  id: PropTypes.number.isRequired,
  groupId: PropTypes.number.isRequired,
  rule: PropTypes.shape({
    field: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    operator: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  onRemoveRule: PropTypes.func.isRequired,
};

export default Rule;
