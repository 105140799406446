/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/10/2018.
 */

import React from "react";
import PropTypes from "prop-types";
const SidebarTab = (props) => {
  const footer = props.footer ? (
    <div className="chat-footer">{props.footer}</div>
  ) : null;

  const content = props.scrollContents ? (
    <div className={props.wapperClassName}>
      <div className="chat-box">{props.children}</div>
    </div>
  ) : (
    props.children
  );

  return (
    <div
      className={"content-full tab-pane " + (props.show ? " active show" : "")}
      id={props.id}
    >
      <div className="chat-window">
        <div className="chat-contents">
          <div className="chat-content-wrap">{content}</div>
        </div>
        {footer}
      </div>
    </div>
  );
};

SidebarTab.defaultProps = {
  scrollContents: true,
  wapperClassName: "chat-wrap-inner builder",
};

SidebarTab.propTypes = {
  show: PropTypes.bool,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string.isRequired,
  scrollContents: PropTypes.bool,
  wapperClassName: PropTypes.string,
};

export default SidebarTab;
