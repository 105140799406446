/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/04/2024
 */

import React, { useCallback, useContext } from "react";
import ShareLink from "./ShareLink";
import QRCode from "./QRCode";
import ShareButtonFacebook from "./ShareButtonFacebook";
import ShareButtonX from "./ShareButtonX";
import LinkCopyInput from "./LinkCopyInput";
import WidgetSettingsContext from "../../contexts/WidgetSettingsContext";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {String} url
 * @param {String} title
 * @param {String} text
 * @param {func} onClose
 * @returns {Element}
 * @constructor
 */
const SharePopup = ({ url, title, text, onClose }) => {
  const calendarSettingsContext = useContext(WidgetSettingsContext);
  const { t } = useTranslation();

  const _onClose = useCallback((e) => {
    onClose(e);
  }, []);

  const showQrCode =
    Number.parseInt(calendarSettingsContext["popupQrCode"]) === 1;

  const showShareLink =
    Number.parseInt(calendarSettingsContext["popupShareLink"]) === 1;

  const showFacebookButton =
    Number.parseInt(calendarSettingsContext["shareFacebookShow"]) === 1;

  const showXButton =
    Number.parseInt(calendarSettingsContext["shareXButtonShow"]) === 1;

  const qrCodeBgColor = calendarSettingsContext["qrCodeBgColor"];
  const qrCodeFgColor = calendarSettingsContext["qrCodeFgColor"];

  const copyLink = showShareLink ? <LinkCopyInput url={url} /> : null;

  return (
    <div
      className="dc-share-popup dc-share-popup--hidden dc-share-popup--fade-in"
      id="dc-share-popup"
    >
      <div className={"dc-share-popup--card"}>
        <div className={"dc-share-popup--card--close"} onClick={_onClose}></div>
        <div className={"dc-share-popup--card-section"}>
          <div className={"dc-share-popup--card-section--header"}>
            {t("Share")}
          </div>
          <div className={"dc-share-popup--card-section--subheader"}>
            {t("Link to")} {title}
          </div>
          <div className={""}>
            <QRCode
              value={url}
              title={null}
              showDownloadButton={false}
              containerClassName={"dc-share-popup--card-section--qr"}
              showQrCode={showQrCode}
              qrCodeBgColor={qrCodeBgColor}
              qrCodeFgColor={qrCodeFgColor}
            />
          </div>
        </div>

        <div className={"dc-share-popup--card-section--buttons"}>
          {showFacebookButton ? (
            <ShareButtonFacebook title={title} url={url} />
          ) : null}
          {showXButton ? <ShareButtonX title={title} url={url} /> : null}
          <ShareLink title={title} url={url} text={text} />
        </div>
        {copyLink}
      </div>
    </div>
  );
};

export default SharePopup;
