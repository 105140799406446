/**
 * Created by piotr.pozniak@thebeaverhead.com on 08/03/2019.
 */

import React from "react";

const DetailsRow = (props) => {
  const { icon, title } = props;

  const titleBlock = title ? (
    <div className="">
      <h3 className="">{title}</h3>
    </div>
  ) : null;
  return (
    <div className={"event-row--details"}>
      <div className="event-row--details-row">
        <div className="col-icon">
          <i className="material-icons">{icon}</i>
        </div>
        <div className="col-content ">
          {titleBlock}
          <div>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default DetailsRow;
