/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/12/2024
 */

import React, { useCallback, useEffect, useState } from "react";
import Form from "./AdminOrgEdit/Form";
import { useOrganizationStore } from "../../../hooks/redux/organization";
import { useHistory } from "react-router-dom";
import ModalTemplate from "./ModalTemplate";
import { useUserStore } from "../../../hooks/redux/user";
import ApiError from "../component/ApiError";

const NewOrganizationSetup = ({}) => {
  const { user, changeOrganization } = useUserStore();
  const {
    organization: { edit, editError, editSuccess, model },
    createOrganization,
  } = useOrganizationStore();
  const history = useHistory();

  const [organization, setOrganization] = useState({
    name: "",
    treasurer_email: user.model.email,
    billing_contact: `${user.model.first_name} ${user.model.last_name}`,
  });

  useEffect(() => {
    if (editSuccess) {
      changeOrganization(model.uuid);
    }
  }, [editSuccess]);

  const onSubmit = useCallback(
    (data) => {
      createOrganization(data);
    },
    [organization]
  );

  const onClose = useCallback(async () => {
    history.goBack();
  }, []);

  return (
    <ModalTemplate
      id={"organization-new-modal"}
      header={"Create new organization"}
      size={"lg"}
      onClose={onClose}
    >
      <Form
        organization={organization}
        onSubmit={onSubmit}
        disabled={edit}
        organizationSettings={organization.model?.settings}
        submitButtonLabel={"Create"}
      />
      <ApiError error={editError} />
    </ModalTemplate>
  );
};

export default NewOrganizationSetup;
